<template>
  <div class="flex flex-col bg-lightGrey rounded-xl text-grey justify-center">
    <div class="w-3/4 flex flex-col m-auto gap-y-5 mb-3">
      <span class="self-start text-2xl font-bold mt-5">{{ title }}</span>
      <v-select class="filterDropDown" placeholder="Search By" v-model="mainFilterValue" :options="mainFilter"
        @option:selected="selectedMainFilter" :class="
          v$.mainFilterValue.$error === true ? 'filterDropDown-error' : ''
        "></v-select>
      <v-select v-if="mainFilterValue?.type !== 'input'" class="filterDropDown" placeholder="Please Select"
        v-model="inputSearchValue" :options="secondaryFilters" :class="
          v$.inputSearchValue.$error === true ? 'filterDropDown-error' : ''
        "></v-select>
      <input v-else :placeholder="inputPlaceHolder" v-model="inputSearchValue" type="text"
        class="border rounded-md p-2 w-full" :class="
          v$.inputSearchValue.$error === true ? 'border-red' : ''
        " />
    </div>

    <div class="flex justify-center items-center">
      <base-button @click="submitSearch" backgroundColour="bg-orange hover:bg-orange-light">Search</base-button>
      <base-button @click="reset">Reset</base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  components: { BaseButton },
  data() {
    return {
      v$: useVuelidate(),
      mainFilterValue: null,
      inputSearchValue: null,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    mainFilter: {
      type: Array,
      required: true,
    },
    secondaryFilters: {
      type: Array,
      default: () => [],
    },
    inputPlaceHolder: {
      type: String,
      default: "",
    },
  },
  validations() {
    return {
      mainFilterValue: { required },
      inputSearchValue: { required },
    };
  },

  methods: {
    selectedMainFilter(value) {
      this.inputSearchValue = null;
      this.$emit("selectedMainFilter", value);
    },
    submitSearch() {
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      } else {
        this.$emit("search", {
          filter: this.mainFilterValue,
          value: this.inputSearchValue,
        })
      }
    },
    reset() {
      this.mainFilterValue = null;
      this.inputSearchValue = null;
      this.$emit("reset", {
        filter: this.mainFilterValue,
        value: this.inputSearchValue,
      });
    },
  },
};
</script>

<style>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border: 1px solid #425563;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}

.filterDropDown .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}

#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown-error .vs__dropdown-toggle,
.filterDropDown-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.filterDropDown-error .vs__dropdown-toggle {
  width: 100%;
}
#filterDropDown-error .filterDropDown .vs__clear,
#filterDropDown-error .filterDropDown .vs__open-indicator {
  fill: black;
}
</style>
